import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Index from '../views/index.vue'
import Login from '../views/login.vue'
import {
	Message
} from "element-ui"

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'view-index',
		component: Index,
		children: [{
				path: '/',
				name: 'view-dashboard',
				component: () => import( /* webpackChunkName: 'view-dashboard' */
					'../views/dashboard/index.vue')
			},
			{
				path: '/menu',
				name: 'view-menu',
				component: () => import( /* webpackChunkName: 'view-menu' */ '../views/menu/index.vue')
			},
			{
				path: '/admin',
				name: 'view-admin',
				component: () => import( /* webpackChunkName: 'view-admin' */ '../views/admin/index.vue')
			},
			{
				path: '/role',
				name: 'view-role',
				component: () => import( /* webpackChunkName: 'view-role' */ '../views/role/index.vue')
			},
			{
				path: '/member',
				name: 'view-member',
				component: () => import( /* webpackChunkName: 'view-member' */ '../views/member/index.vue')
			},
			{
				path: '/admin-log',
				name: 'view-admin-log',
				component: () => import( /* webpackChunkName: 'view-admin-log' */
					'../views/admin-log/index.vue')
			},
			{
				path: '/open-door-records',
				name: 'view-open-door-records',
				component: () => import( /* webpackChunkName: 'view-open-door-records' */
					'../views/open-door-records/index.vue')
			},
			{
				path: '/shop',
				name: 'view-shop',
				component: () => import( /* webpackChunkName: 'view-shop' */ '../views/shop/index.vue')
			},
			{
				path: '/article-type',
				name: 'view-article-type',
				component: () => import( /* webpackChunkName: 'view-article-type' */
					'../views/article-type/index.vue')
			},
			{
				path: '/article',
				name: 'view-article',
				component: () => import( /* webpackChunkName: 'view-article' */ '../views/article/index.vue')
			},
			{
				path: '/system-config',
				name: 'view-system-config',
				component: () => import( /* webpackChunkName: 'view-system-config' */
					'../views/system-config/index.vue')
			},
			{
				path: '/file',
				name: 'view-file',
				component: () => import( /* webpackChunkName: 'view-file' */ '../views/file/index.vue')
			},
			{
				path: '/archive',
				name: 'view-archive',
				component: () => import( /* webpackChunkName: 'view-archive' */ '../views/archive/index.vue')
			},
			{
				path: '/material',
				name: 'view-material',
				component: () => import( /* webpackChunkName: 'view-material' */ '../views/material/index.vue')
			},
			{
				path: '/reservation',
				name: 'view-reservation',
				component: () => import( /* webpackChunkName: 'view-reservation' */
					'../views/reservation/index.vue')
			},
			{
				path: '/rfm',
				name: 'view-rfm',
				component: () => import( /* webpackChunkName: 'view-rfm' */ '../views/rfm/index.vue')
			},
			{
				path: '/rfm-setup',
				name: 'view-rfm-setup',
				component: () => import( /* webpackChunkName: 'view-rfm-setup' */
					'../views/rfm-setup/index.vue')
			},
			{
				path: '/rfm-detail',
				name: 'view-rfm-detail',
				component: () => import( /* webpackChunkName: 'view-rfm-detail' */
					'../views/rfm-detail/index.vue')
			},
			{
				path: '/issue-coupons',
				name: 'view-issue-coupons',
				component: () => import( /* webpackChunkName: 'view-issue-coupons' */
					'../views/issue-coupons/index.vue')
			},
			{
				path: '/coupon',
				name: 'view-coupon',
				component: () => import( /* webpackChunkName: 'view-coupon' */ '../views/coupon/index.vue')
			},
			{
				path: '/goods',
				name: 'view-goods',
				component: () => import( /* webpackChunkName: 'view-goods' */ '../views/goods/index.vue')
			},
			{
				path: '/bi',
				name: 'view-bi',
				component: () => import( /* webpackChunkName: 'view-bi' */ '../views/bi/index.vue')
			},
			{
				path: '/goods/add',
				name: 'view-goods-add',
				component: () => import( /* webpackChunkName: 'view-goods-add' */ '../views/goods/add.vue')
			}, {
				path: '/goods/edit',
				name: 'view-goods-edit',
				component: () => import( /* webpackChunkName: 'view-goods-add' */ '../views/goods/edit.vue')
			},
			{
				path: '/goods-type',
				name: 'view-goods-type',
				component: () => import( /* webpackChunkName: 'view-goods-type' */
					'../views/goods-type/index.vue')
			},
			{
				path: '/order',
				name: 'view-order',
				component: () => import( /* webpackChunkName: 'view-order' */ '../views/order/index.vue')
			}, {
				path: '/banner',
				name: 'article-banner',
				component: () => import('../views/banner/index.vue')
			}, {
				path: '/assignment',
				name: 'article-assignment',
				component: () => import('../views/assignment/index.vue')
			}, {
				path: '/pick',
				name: 'article-pick',
				component: () => import('../views/selfpick/index.vue')
			}, {
				path: '/logistics',
				name: 'article-logistics',
				component: () => import('../views/logistics/index.vue')
			}, {
				path: '/aftersales',
				name: 'article-aftersales',
				component: () => import('../views/aftersales/index.vue')
			}, {
				path: '/agent',
				name: 'article-agent',
				component: () => import('../views/agent/index.vue')
			}, {
				path: '/evaluate',
				name: 'article-evaluate',
				component: () => import('../views/evaluate/index.vue')
			},
			{
				path: '/recommend-list',
				name: 'recommend-list',
				component: () => import('../views/recommend/recommend-list/index.vue')
			},
			{
				path: '/recommend-cate',
				name: 'recommend-cate',
				component: () => import('../views/recommend/recommend-cate/index.vue')
			},
			{
				path: '/scenic-list',
				name: 'scenic-list',
				component: () => import('../views/scenic/scenic-list/index.vue')
			},
			{
				path: '/scenic-cate',
				name: 'scenic-cate',
				component: () => import('../views/scenic/scenic-cate/index.vue')
			},
			{
				path: '/scenic-add',
				name: 'scenic-add',
				component: () => import('../views/scenic/scenic-list/add.vue')
			},
			{
				path: '/scenic-order',
				name: 'scenic-order',
				component: () => import('../views/scenic/scenic-order/index.vue')
			},
			{
				path: '/scenic-refund',
				name: 'scenic-refund',
				component: () => import('../views/scenic/scenic-refund/index.vue')
			},
			{
				path: '/addwork',
				name: 'addwork',
				component: () => import('../views/addwork/index.vue')
			},
			{
				path: '/askleave',
				name: 'askleave',
				component: () => import('../views/askleave/index.vue')
			},
			{
				path: '/reimburse',
				name: 'reimburse',
				component: () => import('../views/reimburse/index.vue')
			}, {
				path: '/user',
				name: 'user',
				component: () => import('../views/user/index.vue')
			}, {
				path: '/clock',
				name: 'clock',
				component: () => import('../views/clock/index.vue')
			}, {
				path: '/wages',
				name: 'wages',
				component: () => import('../views/wages/index.vue')
			}, {
				path: '/transaction',
				name: 'transaction',
				component: () => import('../views/transaction/index.vue')
			},{
				path: '/notice',
				name: 'notice',
				component: () => import('../views/notice/index.vue')
			},{
				path: '/onlineshoot',
				name: 'onlineshoot',
				component: () => import('../views/onlineshoot/index.vue')
			},{
				path: '/newmedia',
				name: 'newmedia',
				component: () => import('../views/newmedia/index.vue')
			},{
				path: '/convergencemedia',
				name: 'convergencemedia',
				component: () => import('../views/convergencemedia/index.vue')
			},{
				path: '/affairs',
				name: 'affairs',
				component: () => import('../views/affairs/index.vue')
			},{
				path: '/other',
				name: 'other',
				component: () => import('../views/other/index.vue')
			},{
				path: '/paymentratio',
				name: 'paymentratio',
				component: () => import('../views/paymentratio/index.vue')
			},{
				path: '/insure',
				name: 'insure',
				component: () => import('../views/insure/index.vue')
			},{
				path: '/vacation',
				name: 'vacation',
				component: () => import('../views/vacation/index.vue')
			},
		]
	},
	{
		path: '/login',
		name: 'view-login',
		component: Login
	},
	{
		path: '*',
		name: 'view-404',
		component: () => import( /* webpackChunkName: 'view-404' */ '../views/404.vue')
	}
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	if (to.path === "/rfm-detail") {
		if (store.state.buttons.findIndex(item => item.path === "rfm-detail") >= 0) {
			next();
		} else {
			Message.error("您没有该页面访问权限");
		}
	} else if (to.path === "/rfm-setup") {
		if (store.state.buttons.findIndex(item => item.path === "rfm-setup") >= 0) {
			next();
		} else {
			Message.error("您没有该页面访问权限");
		}
	} else {
		if (to.path !== '/login' && !store.state.token) {
			router.replace("/login");
		} else {
			next();
		}
	}
})

export default router